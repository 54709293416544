import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"

import { Container } from "react-bootstrap"
import { renderRichText } from "../lib/renderRichText"

import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"

const PrivacyPage = props => {
  const { contentfulPrivacy } = props.data
  const info = documentToPlainTextString(contentfulPrivacy.info.json)
  return (
    <>
      <SEO
        description={info.slice(0, 120)}
        title={contentfulPrivacy.title}
        lang={contentfulPrivacy.node_locale}
      />
      <Container fluid className="pt-4">
        <Container className="p-5 paper">
          <h1 className="font-weight-bold">{contentfulPrivacy.title}</h1>
          <div className="lead">
            {renderRichText(contentfulPrivacy.info.json)}
          </div>
        </Container>
      </Container>
    </>
  )
}

export const pageQuery = graphql`
  query privacyPage($locale: String!) {
    contentfulPrivacy(
      pageTitle: { eq: "privacy" }
      node_locale: { eq: $locale }
    ) {
      info {
        json
      }
      node_locale
      title
    }
  }
`
export default PrivacyPage
